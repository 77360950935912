<template>

  <div
    class="home"
    v-if="!mostrar"
  >

    <div class="mx-4 bg-gray-50 ">
      <div class="my-4 bg-blue-900 ">
        <button
          class="
              relative
              
              flex
              justify-center
              py-2
              px-4
              border border-transparent
              text-sm
              font-medium
              rounded-md
              text-white
              bg-pink-800
              hover:bg-pink-700
              focus:outline-none
              focus:ring-2 focus:ring-offset-2 focus:ring-pink-500
            "
          @click.prevent="pasarPantalla2()"
        >Inicio (Tiene 5 minutos para realizarlo) </button>
      </div>

      <div class="rounded-md shadow-sm -space-y-px flex flex-col">
        <!--<div class="ml-8">
            <h1 class="text-xl mb-2 text-red-900">Cuestionario</h1>
          </div>-->

        <div class=" md:flex mx-8" v-if = "mostrarPregunta == true">
          <div class="md:w-full mr-2">
            <div>
              <ul
                id="example-1"
                class="mb-8"
                
              >
                <li
                  v-for="(item,index) in dataSecuencia"
                  :key="index "
                  class="my-2"
                >
                  {{ index }} - {{ item.preguntaDescripcion}}

                  <ul  id="example-2"   class="ml-8" >
                    <li  v-for="(item2,index2) in item.opcionPregunta"   :key="item2.id"    class="my-2"  v-bind:style="[ item2.seleccion === false ? {color: 'grey', weight: 'bold'} : {color: 'green'}]"  >

                      <span @click="checkedAux(item.id,item2.id)" class="flex justify-start">  

                       <svg
                          class="h-5 w-5 group-hover:text-pink-400 mr-2 cursor-pointer"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                          fill="currentColor"
                          aria-hidden="true"

                        >
                          <path
                            fill-rule="evenodd"
                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                            clip-rule="evenodd"
                          />
                          </svg>    


                      <label    name="data"   v-bind:checked="ctrlChecked"  v-bind:id=item.id v-bind:value="item2.seleccion"  @click="checkedAux(item.id,item2.id)" >
                        {{ item2.id}} - {{ item2.opcion}} 

                        </label>

                     </span> 

                     </li>

                  </ul>

                </li>
              </ul>
                        <!--<button @click="probar()">Aceptar</button>-->
            </div>
          </div>

        </div>
      </div>

      <div>
         

        <button
          class="
              group
              relative
              flex
              justify-center
              py-2
              px-4
              border border-transparent
              text-sm
              font-medium
              rounded-md
              text-white
              bg-blue-900
              hover:bg-pink-300
              focus:outline-none
              focus:ring-2 focus:ring-offset-2 focus:ring-pink-500
              mb-8

            "
          @click.prevent="secuenciaPreguntas()"
          v-if="mostrarBotonSiguiente"
        >

          Siguiente
          </button>

          <button
            class="
              group
              relative
              w-full
              flex
              justify-center
              py-2
              px-4
              border border-transparent
              text-sm
              font-medium
              rounded-md
              text-white
              bg-blue-900
              hover:bg-pink-700
              focus:outline-none
              focus:ring-2 focus:ring-offset-2 focus:ring-pink-500
            "
            @click.prevent="enviarInfo()"
            v-if="mostrarBotonEnviar"
            
          >
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <!-- Heroicon name: solid/lock-closed -->
              <svg
                class="h-5 w-5 text-indigo-500 group-hover:text-pink-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                  clip-rule="evenodd"
                />
                </svg>
            </span>
            Enviar Información
            </button>
      </div>
      <modalProps
        v-show="mostrarModal"
        :titulo="tituloM"
        :msg1="mensajeM"
        :tipos="tipoM"
      ></modalProps>
    </div>
    </div>

</template>

<script>
import { computed, watchEffect, onMounted } from "vue";
import { useStore } from "vuex";
import { ref } from "@vue/reactivity";
import router from "../router/index.js";
import modalLoading from "../components/modalLoading.vue";
import modalProps from "../components/modalPropos.vue";

export default {
  components: {
    modalLoading,
    modalProps
  },
  setup() {
    const store = useStore();
    const dataBase = computed(() => store.state.preguntas);
    const rptaCuestionario = computed(() => store.state.asesora);
    const tituloM = ref("");
    const mensajeM = ref("");
    const tipoM = ref("");
    const data = ref([]);
    const botonEnviarDisabled = ref(true);
    const mostrarPregunta = ref(true);
    const stilo = ref("");

    const ctrlChecked = ref(false);

    const objetoPpal = ref({
      preguntaDescripcion: "",
      id: "",
      questionId: "",
      opcionPregunta: []
    });

    const objetoOpcPregunta = ref({
      opcion: "",
      seleccion: "",
      id: ""
    });

   /* const dataBase = ref([
      {
        preguntaDescripcion:
          "El valor inicial de su crédito de vehículo con LEASING  BOGOTA  S. A. fue:",
        id: "b8cea921-6fc9-4d94-9c2c-253e3efb20c0",
        questionId: "22",
        opcionPregunta: [
          {
            opcion: "ENTRE           $30,000,001 Y           $35,000,000",
            seleccion: false,
            id: "1"
          },
          {
            opcion: "ENTRE           $25,000,001 Y           $30,000,000",
            seleccion: false,
            id: "2"
          },
          {
            opcion: "No tengo crédito de vehículo con la entidad",
            seleccion: false,
            id: "3"
          },
          {
            opcion: "ENTRE           $35,000,001 Y           $40,000,000",
            seleccion: false,
            id: "4"
          }
        ]
      },
      {
        preguntaDescripcion:
          "La cuota mensual de su crédito de modalidad MICROCRÉDITO con LEASING  POPULAR está entre:",
        id: "f36c4fb9-c0db-49cf-beff-0ce11d04ba5d",
        questionId: "41",
        opcionPregunta: [
          {
            opcion: "MAS DE $1.500.000",
            seleccion: false,
            id: "1"
          },
          {
            opcion: "No tengo ningún crédito con esta entidad",
            seleccion: false,
            id: "2"
          },
          {
            opcion: "$1 a $300.000",
            seleccion: false,
            id: "3"
          },
          {
            opcion: "$600.001 a $1.000.000",
            seleccion: false,
            id: "4"
          },
          {
            opcion: "$1.000.001 a $1.500.000",
            seleccion: false,
            id: "5"
          },
          {
            opcion: "No tengo ningún crédito con esta entidad",
            seleccion: false,
            id: "6"
          },
          {
            opcion: "$300.001 a $600.000",
            seleccion: false,
            id: "7"
          }
        ]
      },
      {
        preguntaDescripcion:
          "¿En algún momento usted ha tenido línea celular con COMCEL ?",
        id: "b693ba6f-9a9a-4613-a5b3-faf0723b8669",
        questionId: "35",
        opcionPregunta: [
          {
            opcion: "NO",
            seleccion: false,
            id: "1"
          },
          {
            opcion: "SI",
            seleccion: false,
            id: "2"
          }
        ]
      },
      {
        preguntaDescripcion:
          "¿Cuál o cuáles de los siguientes productos tiene con THE ROYAL BANK OF SCOTLAND COLOMBIA S.A.?",
        id: "823a38e1-fcfe-41b7-8acf-e510118f992d",
        questionId: "20",
        opcionPregunta: [
          {
            opcion: "TARJETA DE CREDITO",
            seleccion: false,
            id: "1"
          },
          {
            opcion: "CUENTA CORRIENTE",
            seleccion: false,
            id: "2"
          },
          {
            opcion: "CUENTA CORRIENTE Y TARJETA DE CREDITO",
            seleccion: false,
            id: "3"
          },
          {
            opcion: "Ninguno de los anteriores",
            seleccion: false,
            id: "4"
          }
        ]
      },
      {
        preguntaDescripcion:
          "¿Con qué entidad usted tiene tarjeta de crédito marca VISA?",
        id: "a6017368-861e-40fc-a238-43b40a095970",
        questionId: "13",
        opcionPregunta: [
          {
            opcion: " BANCO FALABELLA",
            seleccion: false,
            id: "1"
          },
          {
            opcion: "COLPATRIA",
            seleccion: false,
            id: "2"
          },
          {
            opcion: "MUNICIPIO DE ENVIAGADO-SECRETARIA DE HACIENDA",
            seleccion: false,
            id: "3"
          },
          {
            opcion: "NINGUNA DE LAS ANTERIORES",
            seleccion: false,
            id: "4"
          }
        ]
      }
    ]);*/

    const mostrar = computed(() => {
      return store.state.login.mostrar;
    });

    const mostrarModal = computed(() => store.state.mostrarModal);
    watchEffect(() => {
      mostrarModal.value = computed(() => store.state.mostrarModal);
    });

    const modalLoad = computed(() => store.state.mostrarModalLoading);
    watchEffect(() => {
      modalLoad.value = computed(() => Store.state.mostrarModalLoading);
    });

    const datos = ref([]);
    const dataSecuencia = ref();
    const contador = ref(0);
    const mostrarBotonEnviar = ref(false);
    const mostrarBotonSiguiente = ref(true);

    const cerrarmodal = () => {
      store.commit("closeModal");
    };

    const cerrarmodalLoading = () => {
      store.commit("closeModalLoading");
    };

    const probar = () => {
      //await store.dispatch("probar");
      console.log(data.value.length);
      console.log(data.value);

      datos.value = data.value;
      console.log("datos");
      console.log(datos.value);
    };

     const color = () => {
       backgroundColor:"#16a085" 
    };

    const enviarInfo = async () => {
      //let tamano = data.value.pregunta.length;
      //let tamanoRpta = 0;

      /* data.value.pregunta.forEach(function(data) {
            data.opcionPregunta.forEach(function(data2) {
              if (data2.seleccion == true) {
                tamanoRpta = tamanoRpta + 1;
              }
            });
          });*/

      //if (tamano == tamanoRpta) {
      await store.commit("setModalLoadingAux");  

      cerrarmodalLoading();
      await store.dispatch("enviarInfo", data);

      /*console.log("alonso111");
      console.log(rptaCuestionario.value.controlMensaje.trim());*/
      //cerrarmodalLoading();
      if (rptaCuestionario.value.controlMensaje == "DCTOS") {

        console.log("dctos")
        
         store.commit("setModalDirecciones");

         cerrarmodalLoading();
        //cerrarmodal();
        router.push("/about");
        
      } else  {
        botonEnviarDisabled.value = false
        cerrarmodalLoading();

        Swal.fire({
          title: 'Mensaje del sistema!',
          text: rptaCuestionario.value.controlMensaje,
          icon: 'error',
          confirmButtonText: 'Cerrar',
          confirmButtonColor: '#F3566E'
         })

         router.push("/");
      }
     }  

    const pasarPantalla2 = () => {
      store.commit("LimpiarVariables");
      router.push("/");
    };

    const checkedAux = (idItemPregunta, idItemRespuesta) => {
     /* console.log("entro checket");
      console.log(idItemPregunta);
      console.log(idItemRespuesta);*/

      // console.log(dataSecuencia.value)
      //dataSecuencia.value.pregunta.forEach(function(data) {
     /* console.log("data-21");
      console.log(dataSecuencia.value);
      console.log(dataSecuencia.value[0].id);*/
      // if (data.id === idItemPregunta) {
      dataSecuencia.value[0].opcionPregunta.forEach(function(data2) {
        data2.seleccion = false;
        if (data2.id == idItemRespuesta) {
          data2.seleccion = true;
          /*console.log("data-enttro");
          console.log(data2);*/
        }
      });

      //}
      //});
    };

    const secuenciaPreguntas = () => {
      //console.log("dataSecuencia")
      let rpta = "";

      let reg = dataBase.value.pregunta.length;
     /* console.log("total");
      console.log(reg);
      console.log(contador.value);
      console.log(dataSecuencia.value);*/

      let tamanoRpta = 0;

      dataSecuencia.value[0].opcionPregunta.forEach(function(data2) {
        if (data2.seleccion == true) {
          tamanoRpta = tamanoRpta + 1;
        }
      });

      if (tamanoRpta > 0) {
        //console.log(dataSecuencia.value.preguntaDescripcion);
        if (reg > 0) {

          //console.log("total2");
          reg = reg - 1
          //console.log(reg);

          /*console.log("contador.value");
          console.log(contador.value);*/
          if (contador.value <= reg) {
            //if(contador.value == 0){
              mostrarPregunta.value = false
              ctrlChecked.value = true
              almacenarData()
              contador.value++;
              /*console.log("contador-graba");
              console.log(contador.value);*/
            //}else{
              if (contador.value <= reg) {
              dataSecuencia.value = [];
              let Elemento = dataBase.value.pregunta[contador.value];

              /* console.log("Elemento");
               console.log(Elemento);*/

              limpiarObjPpal();

              objetoPpal.value.preguntaDescripcion = Elemento.preguntaDescripcion;
              objetoPpal.value.id = Elemento.id;
              objetoPpal.value.questionId = Elemento.questionId;

              Elemento.opcionPregunta.forEach(function(data2) {
                limpiarOpcPregunta();

                objetoOpcPregunta.value.opcion = data2.opcion;
                objetoOpcPregunta.value.seleccion = data2.seleccion;
                objetoOpcPregunta.value.id = data2.id;

                objetoPpal.value.opcionPregunta.push(objetoOpcPregunta.value);
              });

              dataSecuencia.value.push(objetoPpal.value);
               mostrarPregunta.value = true
            }else{
              mostrarBotonEnviar.value = true;
            mostrarBotonSiguiente.value = false;
            dataSecuencia.value = [];
            }

          } else {
            mostrarBotonEnviar.value = true;
            mostrarBotonSiguiente.value = false;
            dataSecuencia.value = [];
          }
        }
        //contador.value++;

        rpta = "OK";
      } else {
        rpta = "FALLO";
        Swal.fire({
          title: 'Mensaje del sistema!',
          text: 'Debe seleccionar una opciòn',
          icon: 'error',
          confirmButtonText: 'Cerrar',
          confirmButtonColor: '#F3566E'
         })
      }

      return rpta;
    };

    const inicial = () => {
      //console.log("dataSecuencia")
      dataSecuencia.value = [];
      let reg = dataBase.value.pregunta.length;
     /* console.log("total-2");
      console.log(reg);*/
      //console.log(contador.value);
     // console.log(dataBase.value.pregunta);

      let Elemento = dataBase.value.pregunta[contador.value];

      limpiarObjPpal();

      objetoPpal.value.preguntaDescripcion = Elemento.preguntaDescripcion;
      objetoPpal.value.id = Elemento.id;
      objetoPpal.value.questionId = Elemento.questionId;

      Elemento.opcionPregunta.forEach(function(data2) {
        limpiarOpcPregunta();

        objetoOpcPregunta.value.opcion = data2.opcion;
        objetoOpcPregunta.value.seleccion = data2.seleccion;
        objetoOpcPregunta.value.id = data2.id;

        objetoPpal.value.opcionPregunta.push(objetoOpcPregunta.value);
      });

      dataSecuencia.value.push(objetoPpal.value);
      //contador.value++;

     /* console.log("dataSecuencia.value");
      console.log(dataSecuencia.value);*/
    };

    const almacenarData = () => {
      //dataSecuencia.value.push(objetoPpal.value);

     /* console.log("dataSecuencia.value-333");
      console.log(dataSecuencia.value);*/

      limpiarObjPpal();

      objetoPpal.value.preguntaDescripcion =
        dataSecuencia.value[0].preguntaDescripcion;
      objetoPpal.value.id = dataSecuencia.value[0].id;
      objetoPpal.value.questionId = dataSecuencia.value[0].questionId;

      dataSecuencia.value[0].opcionPregunta.forEach(function(data2) {
        limpiarOpcPregunta();

        objetoOpcPregunta.value.opcion = data2.opcion;
        objetoOpcPregunta.value.seleccion = data2.seleccion;
        objetoOpcPregunta.value.id = data2.id;

        objetoPpal.value.opcionPregunta.push(objetoOpcPregunta.value);
      });

      data.value.push(objetoPpal.value);
     /* console.log("dataFinal");
      console.log(data.value);*/
      //contador.value++;
    };

    const validarPreguntaOpciones = async () => {
      let tamano = data.value.pregunta.length;
      let tamanoRpta = 0;

      dataSecuencia.value[0].opcionPregunta.forEach(function(data2) {
        if (data2.seleccion == true) {
          tamanoRpta = tamanoRpta + 1;
        }
      });
    };

    const limpiarObjPpal = async () => {
      objetoPpal.value = {
        preguntaDescripcion: "",
        id: "",
        questionId: "",
        opcionPregunta: []
      };
    };

    const limpiarOpcPregunta = async () => {
      objetoOpcPregunta.value = {
        opcion: "",
        seleccion: "",
        id: ""
      };
    };

    onMounted(() => inicial());

    return {
      probar,
      data,
      mostrar,
      checkedAux,
      enviarInfo,
      datos,
      rptaCuestionario,
      tituloM,
      mensajeM,
      tipoM,
      cerrarmodalLoading,
      modalLoad,
      mostrarModal,
      cerrarmodal,
      pasarPantalla2,
      secuenciaPreguntas,
      dataSecuencia,
      contador,
      mostrarBotonEnviar,
      mostrarBotonSiguiente,
      validarPreguntaOpciones,
      inicial,
      dataBase,
      objetoPpal,
      objetoOpcPregunta,
      limpiarOpcPregunta,
      limpiarObjPpal,
      almacenarData,
      botonEnviarDisabled,
      mostrarPregunta,
      ctrlChecked,
      stilo,
      color
    };
  }
};
</script>
