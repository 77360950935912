<template>
  <div class="about">
    <div class="mx-4 bg-gray-50">
      <div class="my-4 bg-blue-900">
        <button
          class="relative flex justify-left py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-900 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
          @click.prevent="pasarPantalla2()"
        >
          INICIO
        </button>
      </div>

      <input type="hidden" name="remember" value="true" />

      <div class="rounded-md shadow-sm -space-y-px flex flex-col">
        <div class="ml-8">
          <h1 class="text-xl mb-2 text-red-700 text-center">PAGO ANTICIPADO</h1>
          <h1 class="text-xl mb-2 text-red-900">Datos Personales</h1>
        </div>

        <div class="md:flex mx-8">
          <div class="md:w-1/2 mr-2">
            <label for="cedula2" class="">Cedula * </label>
            <input
              disabled
              v-model="datosFormulario.cedula"
              id="cedula2"
              name="cedula2"
              type="number"
              required
              class="appearance-none rounded-none relative block w-full px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm mb-2"
              placeholder="Cedula"
            />
          </div>

          <div class="md:w-1/2 mr-2">
            <label for="Nombre" class="">Nombre * </label>
            <input
              v-model="datosFormulario.nombre"
              id="Nombre"
              name="Nombre"
              type="text"
              required
              class="appearance-none rounded-none relative block w-full px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm mb-2"
              placeholder="Nombre"
            />
          </div>

          <div class="md:w-1/2 mr-2">
            <label for="IdEmail" class="">Apellido * </label>
            <input
              v-model="datosFormulario.apellido"
              id="IdApellido"
              name="IdApellido"
              type="text"
              required
              class="appearance-none rounded-none relative block w-full px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm mb-2"
              placeholder="Apellido"
            />
          </div>

          <div class="md:w-1/2 mr-2">
            <label for="IdCelular" class="">Celular * </label>
            <input
              v-model="datosFormulario.celular"
              id="IdCelular"
              name="IdCelular"
              type="number"
              required
              class="appearance-none rounded-none relative block w-full px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm mb-2"
              placeholder="Celular"
            />
          </div>

          <div class="md:w-1/2 mr-2">
            <label for="IdCelular2" class="">Celular Opcional </label>
            <input
              v-model="datosFormulario.celularOpcional"
              id="IdCelular2"
              name="IdCelular2"
              type="number"
              required
              class="appearance-none rounded-none relative block w-full px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm mb-2"
              placeholder="Celular Opcional"
            />
          </div>
        </div>

        <div class="md:flex mx-8">
          <div class="md:w-1/2 mr-2">
            <label for="IdDpto" class="">Departamento </label>
            <br />
            <select @change="setDepartamento($event)" class="w-64">
              <option
                v-for="(lstDepartamento, index) in lstDepartamentos"
                :value="lstDepartamento.nombreDepartamento"
                :key="index"
              >
                {{ lstDepartamento.nombreDepartamento }}
              </option>
            </select>
          </div>

          <div class="md:w-1/2 mr-2">
            <label for="IdCiudad" class="">Ciudad </label>
            <br />
            <select @change="setCiudad($event)" class="w-64">
              <option
                v-for="(lstCiudad, index) in lstCiudades"
                :value="lstCiudad.nombreCiudad"
                :key="index"
              >
                {{ lstCiudad.nombreCiudad }}
              </option>
            </select>
          </div>

          <div class="md:w-1/2 mr-2">
            <label for="IdPoblado" class="">Poblado </label>
            <br />
            <select @change="setPoblado($event)" class="w-64">
              <option
                v-for="(lstPoblado, index) in lstPoblados"
                :value="lstPoblado.nombrePoblado"
                :key="index"
              >
                {{ lstPoblado.nombrePoblado }}
              </option>
            </select>
          </div>

          <div class="md:w-1/2 mr-2">
            <label for="IdBarrio" class="">Barrio * </label>
            <input
              v-model="datosFormulario.barrio"
              id="IdBarrio"
              name="IdBarrio"
              type="text"
              required
              class="appearance-none rounded-none relative block w-full px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm mb-2"
              placeholder="Barrio"
            />
          </div>

          <div class="md:w-1/2 mr-2">
            <label for="IdSexo" class="">Sexo </label>
            <br />
            <select @change="setSexo($event)" class="w-32">
              <option
                v-for="(lstSexo, index) in lstSexos"
                :value="lstSexo"
                :key="index"
              >
                {{ lstSexo }}
              </option>
            </select>
          </div>
        </div>

        <div class="md:flex mx-8">
          <div class="md:w-1/2 mr-2">
            <label for="cedula2" class="">Direccion Residencia * </label>
            <input
              disabled
              v-model="direccionCompleta"
              id="IdDireccion"
              name="IdDireccion"
              type="text"
              required
              class="relative block w-full appearance-none rounded-none w-80 px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm mb-2 mr-2"
              placeholder="Direccion Residencia"
            />

            <button
              class="py-1 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-pink-800 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 mr-2"
              @click.prevent="cerrarModalDir()"
            >
              Generar Dirección Residencia
            </button>
          </div>

          <div class="md:w-1/2 mr-2">
            <label for="cedula2" class="">Direccion Entrega </label>
            <input
              disabled
              v-model="direccionCompletaEntrega"
              id="IdDireccionEntrega"
              name="IdDireccionEntrega"
              type="text"
              required
              class="relative block w-full appearance-none rounded-none w-80 px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm mb-2 mr-2"
              placeholder="Direccion Entrega"
            />

            <button
              class="py-1 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-pink-800 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 mr-2"
              @click.prevent="cerrarModalDirEntega()"
            >
              Generar Dirección Entrega
            </button>
          </div>

          <div class="md:w-1/2 mr-2">
            <label for="IdCelular" class="">Fecha Nacimiento * </label>
            <input
              v-model="datosFormulario.fechaNacimiento"
              id="IdFechaNacimiento"
              name="IdFechaNacimiento"
              type="Date"
              required
              class="appearance-none rounded-none relative block w-full px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm mb-2"
              placeholder="Fecha Nacimiento"
            />
          </div>

          <div class="md:w-1/2 mr-2">
            <label for="IdCelular2" class="">Referente * </label>
            <input
              v-model="datosFormulario.referente"
              id="IdReferente"
              name="IdReferente"
              type="number"
              required
              class="appearance-none rounded-none relative block w-full px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm mb-2"
              placeholder="Referente"
            />
          </div>

          <div class="md:w-1/2 mr-2">
            <label for="IdEstrato" class="">Estrato </label>
            <br />
            <select @change="setEstrato($event)" class="w-32">
              <option
                v-for="(lstEstrato, index) in lstEstratos"
                :value="lstEstrato"
                :key="index"
              >
                {{ lstEstrato }}
              </option>
            </select>
          </div>
        </div>

        <div class="md:flex mx-8">
          <div class="md:w-1/2 mr-2">
            <label for="idSeccion" class="">Sección * </label>
            <input
              v-model="datosFormulario.seccion"
              id="idSeccion"
              name="idSeccion"
              type="number"
              required
              class="appearance-none rounded-none relative block w-full px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm mb-2"
              placeholder="Seccion"
            />
          </div>

          <div class="md:w-1/2 mr-2">
            <label for="IdEmail" class="">Email * </label>
            <input
              v-model="datosFormulario.email"
              id="IddEmail"
              name="IddEmail"
              type="Email"
              required
              class="appearance-none rounded-none relative block w-full px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm mb-2"
              placeholder="Email"
            />
          </div>

          <div class="md:w-1/2 mr-2">
            <label for="FechaExped" class="">Fecha Expedición </label>
            <input
              v-model="datosFormulario.fechaExpedicion"
              id="FechaExped"
              name="FechaExped"
              type="Date"
              required
              class="appearance-none rounded-none relative block w-full px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm mb-2"
              placeholder="Fecha Expedicion"
            />
          </div>

          <div class="md:w-1/2 mr-2">
            <!--<label
              for="IdCelular"
              class=""
            >Celular * </label>
              <input
                disabled
                v-model="datosFormulario.celular"
                id="IdCelular"
                name="IdCelular"
                type="number"
                required
                class="
                appearance-none
                rounded-none
                relative
                block
                w-full
                px-3
                py-1
                border border-gray-300
                placeholder-gray-500
                text-gray-900
                rounded-t-md
                focus:outline-none
                focus:ring-indigo-500
                focus:border-indigo-500
                focus:z-10
                sm:text-sm
                mb-2
              "
                placeholder="Celular"
              />-->
          </div>

          <div class="md:w-1/2 mr-2">
            <!--<label
              for="IdCelular2"
              class=""
            >Celular Opcional </label>
              <input
                v-model="datosFormulario.celularOpcional"
                id="IdCelular2"
                name="IdCelular2"
                type="number"
                required
                class="
                appearance-none
                rounded-none
                relative
                block
                w-full
                px-3
                py-1
                border border-gray-300
                placeholder-gray-500
                text-gray-900
                rounded-t-md
                focus:outline-none
                focus:ring-indigo-500
                focus:border-indigo-500
                focus:z-10
                sm:text-sm
                mb-2
              "
                placeholder="Celular Opcional"
              />-->
          </div>

          <div class="md:w-1/2 mr-2">
            <!--<label
              for="IdEmail"
              class=""
            >Email * </label>
              <input
                v-model="datosFormulario.email"
                id="IddEmail"
                name="IddEmail"
                type="Email"
                required
                class="
                appearance-none
                rounded-none
                relative
                block
                w-full
                px-3
                py-1
                border border-gray-300
                placeholder-gray-500
                text-gray-900
                rounded-t-md
                focus:outline-none
                focus:ring-indigo-500
                focus:border-indigo-500
                focus:z-10
                sm:text-sm
                mb-2
              "
                placeholder="Email"
              />-->
          </div>
        </div>

        <!--ref-->

        <div class="ml-8">
          <h1 class="text-xl mb-2 mt-4 text-red-900">Referencia Familiar</h1>
        </div>

        <div class="md:flex mx-8">
          <!--<div class="md:w-1/2 mr-2">
            <label
              for="IdCedulaReffam"
              class=""
            >Cedula * </label>
              <input
                v-model="datosFormulario.cedulaRF"
                id="IdCedulaReffam"
                name="IdCedulaReffam"
                type="number"
                required
                class="
                invisible
                appearance-none
                rounded-none
                relative
                block
                w-full
                px-3
                py-1
                border border-gray-300
                placeholder-gray-500
                text-gray-900
                rounded-t-md
                focus:outline-none
                focus:ring-indigo-500
                focus:border-indigo-500
                focus:z-10
                sm:text-sm
                mb-2
              "
                placeholder="Cedula"
              />

          </div>-->

          <div class="md:w-1/2 mr-2">
            <label for="IdNombreRefFam" class="">Primer Nombre * </label>
            <input
              v-model="datosFormulario.primerNombreRF"
              id="IdNombreRefFam"
              name="IdNombreRefFam"
              type="text"
              required
              class="appearance-none rounded-none relative block w-full px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm mb-2"
              placeholder="Primer Nombre"
            />
          </div>

          <div class="md:w-1/2 mr-2">
            <label for="IdSegundoNombreREfFam" class="">Segundo Nombre </label>
            <input
              v-model="datosFormulario.segundoNombreRF"
              id="IdSegundoNombreREfFam"
              name="IdSegundoNombreREfFam"
              type="text"
              required
              class="appearance-none rounded-none relative block w-full px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm mb-2"
              placeholder="Segundo Nombre"
            />
          </div>

          <div class="md:w-1/2 mr-2">
            <label for="IdPrimerApellidoRefFam" class=""
              >Primer Apellido *
            </label>
            <input
              v-model="datosFormulario.primerApellidoRF"
              id="IdPrimerApellidoRefFam"
              name="IdPrimerApellidoRefFam"
              type="text"
              required
              class="appearance-none rounded-none relative block w-full px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm mb-2"
              placeholder="Primer Apellido"
            />
          </div>

          <div class="md:w-1/2 mr-2">
            <label for="IdSegundoApellidoRefFam" class=""
              >Segundo Apellido
            </label>
            <input
              v-model="datosFormulario.segundoApelidoRF"
              id="IdSegundoApellidoRefFam"
              name="IdSegundoApellidoRefFam"
              type="text"
              required
              class="appearance-none rounded-none relative block w-full px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm mb-2"
              placeholder="Segundo Apellido"
            />
          </div>

          <div class="md:w-1/2 mr-2 mb-6">
            <label for="IdParentezco" class="">Parentezco </label>
            <br />
            <select @change="setParentezco($event)" class="w-32">
              <option
                v-for="(lstParentezco, index) in lstParentezcos"
                :value="lstParentezco"
                :key="index"
              >
                {{ lstParentezco }}
              </option>
            </select>
          </div>
        </div>

        <div class="md:flex mx-8">
          <div class="md:w-1/2 mr-2">
            <label for="IdTelefonoRefFam" class="">Telefono * </label>
            <input
              v-model="datosFormulario.telefonoRF"
              id="IdTelefonoRefFam"
              name="IdTelefonoRefFam"
              type="text"
              required
              class="appearance-none rounded-none relative block w-full px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm mb-2"
              placeholder="Telefono"
            />
          </div>

          <div class="md:w-1/2 mr-2">
            <!--<label
              for="IdSegundoNombreREfFam"
              class=""
            >Segundo Nombre </label>
              <input
                v-model="datosFormulario.segundoNombreRF"
                id="IdSegundoNombreREfFam"
                name="IdSegundoNombreREfFam"
                type="text"
                required
                class="
                appearance-none
                rounded-none
                relative
                block
                w-full
                px-3
                py-1
                border border-gray-300
                placeholder-gray-500
                text-gray-900
                rounded-t-md
                focus:outline-none
                focus:ring-indigo-500
                focus:border-indigo-500
                focus:z-10
                sm:text-sm
                mb-2
              "
                placeholder="Segundo Nombre"
              />-->
          </div>

          <div class="md:w-1/2 mr-2">
            <!--<label
              for="IdPrimerApellidoRefFam"
              class=""
            >Primer Apellido * </label>
              <input
                v-model="datosFormulario.primerApellidoRF"
                id="IdPrimerApellidoRefFam"
                name="IdPrimerApellidoRefFam"
                type="text"
                required
                class="
                appearance-none
                rounded-none
                relative
                block
                w-full
                px-3
                py-1
                border border-gray-300
                placeholder-gray-500
                text-gray-900
                rounded-t-md
                focus:outline-none
                focus:ring-indigo-500
                focus:border-indigo-500
                focus:z-10
                sm:text-sm
                mb-2
              "
                placeholder="Primer Apellido"
              />-->
          </div>

          <div class="md:w-1/2 mr-2">
            <!--<label
              for="IdSegundoApellidoRefFam"
              class=""
            >Segundo Apellido </label>
              <input
                v-model="datosFormulario.segundoApelidoRF"
                id="IdSegundoApellidoRefFam"
                name="IdSegundoApellidoRefFam"
                type="text"
                required
                class="
                appearance-none
                rounded-none
                relative
                block
                w-full
                px-3
                py-1
                border border-gray-300
                placeholder-gray-500
                text-gray-900
                rounded-t-md
                focus:outline-none
                focus:ring-indigo-500
                focus:border-indigo-500
                focus:z-10
                sm:text-sm
                mb-2
              "
                placeholder="Segundo Apellido"
              />-->
          </div>

          <div class="md:w-1/2 mr-2 mb-6">
            <!--<label
              for="IdParentezco"
              class=""
            >Parentezco </label>
              <br>
              <select @change="setParentezco($event)" class="w-32">
                <option
                  v-for="(lstParentezco,index) in lstParentezcos"
                  :value="lstParentezco"
                  :key="index"
                >{{lstParentezco}}</option>
              </select>-->
          </div>
        </div>

        <!--ref-->

        <!--ref Personal-->

        <div class="ml-8">
          <h1 class="text-xl mb-2 text-red-900">Referencia Personal</h1>
        </div>

        <div class="md:flex mx-8">
          <div class="md:w-1/2 mr-2">
            <label for="IdNombreRefPer" class="">Primer Nombre * </label>
            <input
              v-model="datosFormulario.primerNombreRP"
              id="IdNombreRefPer"
              name="IdNombreRefPer"
              type="text"
              required
              class="appearance-none rounded-none relative block w-full px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm mb-2"
              placeholder="Primer Nombre"
            />
          </div>

          <div class="md:w-1/2 mr-2">
            <label for="IdSegundoNombreREfPer" class="">Segundo Nombre </label>
            <input
              v-model="datosFormulario.segundoNombreRP"
              id="IdSegundoNombreREfPer"
              name="IdSegundoNombreREfPer"
              type="text"
              required
              class="appearance-none rounded-none relative block w-full px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm mb-2"
              placeholder="Segundo Nombre"
            />
          </div>

          <div class="md:w-1/2 mr-2">
            <label for="IdPrimerApellidoRefPer" class=""
              >Primer Apellido *
            </label>
            <input
              v-model="datosFormulario.primerApellidoRP"
              id="IdPrimerApellidoRefPer"
              name="IdPrimerApellidoRefPer"
              type="text"
              required
              class="appearance-none rounded-none relative block w-full px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm mb-2"
              placeholder="Primer Apellido"
            />
          </div>

          <div class="md:w-1/2 mr-2">
            <label for="IdSegundoApellidoRefPer" class=""
              >Segundo Apellido
            </label>
            <input
              v-model="datosFormulario.segundoApelidoRP"
              id="IdSegundoApellidoRefPer"
              name="IdSegundoApellidoRefPer"
              type="text"
              required
              class="appearance-none rounded-none relative block w-full px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm mb-2"
              placeholder="Segundo Apellido"
            />
          </div>

          <div class="md:w-1/2 mr-2">
            <label for="IdTelefonoRefPE" class="">Telefono * </label>
            <input
              v-model="datosFormulario.telefonoRP"
              id="IdTelefonoRefP"
              name="IdTelefonoRefP"
              type="text"
              required
              class="appearance-none rounded-none relative block w-full px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm mb-2"
              placeholder="Telefono"
            />
          </div>
        </div>

        <!--ref-->
      </div>

      <div>
        <button
          type="submit"
          class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-900 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
          @click.prevent="enviarInformacion()"
        >
          <span class="absolute left-0 inset-y-0 flex items-center pl-3">
            <!-- Heroicon name: solid/lock-closed -->
            <svg
              class="h-5 w-5 text-indigo-500 group-hover:text-pink-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                clip-rule="evenodd"
              />
            </svg>
          </span>
          Procesar Información
        </button>
      </div>

      <modalProps
        v-show="mostrarModalAux"
        :titulo="tituloM"
        :msg1="mensajeM"
        :tipos="tipoM"
      ></modalProps>
      <modalDireccion v-show="modalDir"></modalDireccion>
    </div>

    <h1
      v-if="show"
      :style="backgroundColor"
      class="text-center mt-8 mx-8 text-lg rounded-md"
    >
      {{ resultadoFinal }}
    </h1>
    <modalLoading v-show="modalLoad"></modalLoading>
    <modalDireccionEntrega v-show="modalLoadEntrega"></modalDireccionEntrega>
    <modalPagAnticipado v-show="modalPagoAnt"></modalPagAnticipado>
  </div>
</template>

<script>
import { computed, onMounted, watchEffect } from "vue";
import { useStore } from "vuex";
import { ref } from "@vue/reactivity";
import router from "../router/index.js";
import modalProps from "../components/modalPropos.vue";

import modalDireccion from "../components/modalDireccion.vue";
import modalLoading from "../components/modalLoading.vue";
import modalDireccionEntrega from "../components/modalDireccionEntrega.vue";
import modalPagAnticipado from "../components/modalPagoAnticipado.vue";

export default {
  components: {
    modalProps,
    modalDireccion,
    modalLoading,
    modalDireccionEntrega,
    modalPagAnticipado,
  },
  setup() {
    const store = useStore();

    const cedula = ref("");
    const disabled = ref(0);
    const mensajeReingresoAux = ref("");
    const backgroundColor = ref("#FFFFFF");
    const tituloM = ref("");
    const mensajeM = ref("");
    const tipoM = ref("");
    const mostrarModal = computed(() => store.state.ModalDireccionAux);
    const mostrarModalEntrega = computed(
      () => store.state.ModalDireccionEntrega
    );
    const show = ref(false);
    const resultadoFinal = ref("");
    const departamento = ref("");
    const ciudad = ref("");
    const poblado = ref("");
    const sexo = ref("M");
    const estrato = ref("1");
    const parentezco = ref("BISABUELO(A)");
    const parametros = ref({
      cedula: "",
      check: "1",
    });

    const datosFormulario = ref({
      cedula: "",
      nombre: "",
      apellido: "",
      celular: "",
      celularOpcional: "",
      email: "",
      departamento: "",
      ciudad: "",
      poblado: "",
      barrio: "",
      sexo: "",
      estrato: "",
      direccion: "",
      direccionEntrega: "",
      fechaNacimiento: "",
      referente: "",
      cedulaRF: "0",
      primerNombreRF: "",
      segundoNombreRF: "",
      primerApellidoRF: "",
      segundoApelidoRF: "",
      parentezcoRF: "",
      cedulaRP: "0",
      primerNombreRP: "",
      segundoNombreRP: "",
      primerApellidoRP: "",
      segundoApelidoRP: "",
      seccion: "",
      telefonoRF: "",
      telefonoRP: "",
    });

    const lstParentezcos = ref([
      "BISABUELO(A)",
      "ABUELO(A)",
      "BISNIETO(A)",
      "CONYUGE",
      "HERMANO",
      "HIJO(A)",
      "MADRE",
      "NIETO(A)",
      "PADRE",
      "PRIMO(A)",
      "SOBRINO(A)",
      "TIO(A)",
    ]);
    const lstSexos = ref(["M", "H"]);
    const lstEstratos = ref(["1", "2", "3", "4", "5", "6", "7", "8", "9"]);

    const parametrosUbicacionGeografica = ref({
      idDepartamento: "",
      idCiudad: "1",
    });

    const modalLoad = computed(() => store.state.mostrarModalLoading);
    watchEffect(() => {
      modalLoad.value = computed(() => Store.state.mostrarModalLoading);
    });

    const modalLoadEntrega = computed(() => store.state.ModalDireccionEntrega);
    watchEffect(() => {
      modalLoadEntrega.value = computed(
        () => Store.state.ModalDireccionEntrega
      );
    });

    const modalDir = computed(() => store.state.ModalDireccionAux);
    watchEffect(() => {
      modalDir.value = computed(() => store.state.ModalDireccionAux);
    });

    const modalPagoAnt = computed(() => store.state.ModalPagoAnticipado);
    watchEffect(() => {
      modalPagoAnt.value = computed(() => Store.state.ModalPagoAnticipado);
    });

    const mostrarModalAux = computed(() => store.state.mostrarModal);
    watchEffect(() => {
      mostrarModalAux.value = computed(() => Store.state.mostrarModal);
    });

    const mostrar = computed(() => {
      return store.state.login.mostrar;
    });

    const idZona = computed(() => {
      return store.state.idZona;
    });

    const lstDepartamentos = computed(() => {
      return store.state.departamentos;
    });

    const lstCiudades = computed(() => {
      return store.state.ciudades;
    });

    const lstPoblados = computed(() => {
      return store.state.poblados;
    });

    const estadoConsulta = computed(() => {
      return store.state.resultCifin;
    });

    const direccionCompleta = computed(() => {
      return store.state.direccionCompleta;
    });

    const direccionCompletaEntrega = computed(() => {
      return store.state.direccionCompletaEntrega;
    });

    const asesoraAux = computed(() => store.state.asesora);
    const cedulaAsesora = computed(() => store.state.cedulaAsesora);
    const celularAsesora = computed(() => store.state.celularAsesora);
    const fechaNacimientoAsesora = computed(() => store.state.fechaNacimiento);
    const mensajeFinal = computed(() => store.state.respuestaGrabar);
    const mensaReingreso = computed(() => store.state.mensajeReingreso);

    /*watchEffect(() => {
      mostrarModal.value = computed(() => store.state.mostrarModal);
    });*/

    const cerrarmodal = () => {
      store.commit("closeModal");
    };

    const cerrarmodalLoading = () => {
      store.commit("closeModalLoading");
    };

    const cerrarModalDir = () => {
      console.log("cerrarModalDir");
      store.commit("ModalDireccion");
    };

    const cerrarModalDirEntega = () => {
      store.commit("ModalDireccionEntrega");
    };

    const cerrarModalPagoAnticipado = () => {
      store.commit("ModalPagoAnticipado");
    };

    const on = () => {
      parametros.value.check = "1";
    };

    const off = () => {
      parametros.value.check = "2";
    };

    const limpiar = () => {
      show.value = false;
    };

    const limpiarCedula = () => {
      resultadoFinal.value = "";
    };

    const verificarCedula = async () => {
      await store.dispatch("verificarCedulaConsulta", parametros.value);

      if (estadoConsulta.value.resultado === "APROBADO") {
        resultadoFinal.value =
          "Asesor (a) " +
          estadoConsulta.value.nombre +
          " su estado es " +
          estadoConsulta.value.resultado;
        show.value = true;
        backgroundColor.value = "background: #16a085;";
      } else if (estadoConsulta.value.resultado === "SINTOKEN") {
        resultadoFinal.value = "";
        await store.dispatch("login/cambiarMotrar");
        router.push("/");
      } else if (estadoConsulta.value.resultado === "NOEXISTE") {
        resultadoFinal.value = "";
        show.value = false;
        tituloM.value = "Oops";
        mensajeM.value = "La asesora no esta registrada";
        tipoM.value = "2";
        cerrarmodal();
      } else {
        resultadoFinal.value =
          "Asesor (a) " +
          estadoConsulta.value.nombre +
          " su estado es " +
          estadoConsulta.value.resultado;
        show.value = true;
        backgroundColor.value = "background: #df0808;";
      }
    };

    const getIdZona = async () => {
      direccionCompleta.value = "";

      if (mensaReingreso.value.trim() == "NUEVANOVERIFICADA") {
        mensajeReingresoAux.value = "Nueva CA";
      }

      if (mensaReingreso.value.trim() == "REINCORPORACION") {
        mensajeReingresoAux.value = "Reincorporación";
      }

      if (mensaReingreso.value.trim() == "REINGRESO") {
        mensajeReingresoAux.value = "Reingreso";
        disabled.value = 1;
      } else {
        disabled.value = 0;
      }

      if (asesoraAux.value.SegundoNombre == null) {
        asesoraAux.value.SegundoNombre = ".";
      }

      if (asesoraAux.value.SegundoApellido == null) {
        asesoraAux.value.SegundoApellido = ".";
      }

      console.log("entro--cedula asesora");
      console.log(cedulaAsesora.value);

      datosFormulario.value.cedula = cedulaAsesora.value;

      /*datosFormulario.value.cedula = cedulaAsesora.value;
      datosFormulario.value.celular = celularAsesora.value;
      datosFormulario.value.fechaNacimiento =  fechaNacimientoAsesora.value;
      datosFormulario.value.nombre =  
        asesoraAux.value.primerNombre +
        " " +
        asesoraAux.value.SegundoNombre +
        " " +
        asesoraAux.value.primerApellido +
        " " +
        asesoraAux.value.SegundoApellido;*/

      await store.dispatch("getIdZona");

      await store.dispatch("getDepartamentos", idZona.value);

      if (lstDepartamentos.value.length > 0) {
        await store.dispatch(
          "getCiudades",
          lstDepartamentos.value[0].nombreDepartamento
        );
        if (lstCiudades.value.length > 0) {
          parametrosUbicacionGeografica.idDepartamento =
            lstDepartamentos.value[0].nombreDepartamento;
          parametrosUbicacionGeografica.idCiudad =
            lstCiudades.value[0].nombreCiudad;

          await store.dispatch("getPoblados", parametrosUbicacionGeografica);

          departamento.value = lstDepartamentos.value[0].nombreDepartamento;
          ciudad.value = lstCiudades.value[0].nombreCiudad;
          poblado.value = lstPoblados.value[0].nombrePoblado;
        }
      }
    };

    const setDepartamento = async (idDepartamento) => {
      departamento.value = idDepartamento.target.value;
      await store.dispatch("getCiudades", departamento.value);

      parametrosUbicacionGeografica.idDepartamento = departamento.value;
      parametrosUbicacionGeografica.idCiudad =
        lstCiudades.value[0].nombreCiudad;

      ciudad.value = lstCiudades.value[0].nombreCiudad;

      await store.dispatch("getPoblados", parametrosUbicacionGeografica);

      poblado.value = lstPoblados.value[0].nombrePoblado;
    };

    const setCiudad = async (idCiudad) => {
      ciudad.value = idCiudad.target.value;
      //await store.dispatch("getCiudades",departamento.value);
      parametrosUbicacionGeografica.idDepartamento = departamento.value;
      parametrosUbicacionGeografica.idCiudad = ciudad.value;

      await store.dispatch("getPoblados", parametrosUbicacionGeografica);

      poblado.value = lstPoblados.value[0].nombrePoblado;
      //console.log('poblado----')
      //console.log(poblado.value)
    };

    const setPoblado = (idPoblado) => {
      //console.log('idPoblado')
      //console.log(idPoblado.target.value)
      poblado.value = idPoblado.target.value;
    };

    const setParentezco = (idParentezco) => {
      //console.log('idParentezco')
      //console.log(idParentezco.target.value)
      parentezco.value = idParentezco.target.value;
    };

    const setEstrato = (idEstrato) => {
      //console.log('idEstrato')
      //console.log(idEstrato.target.value)
      estrato.value = idEstrato.target.value;
    };

    const setSexo = (idSexo) => {
      //console.log('idSexo')
      //console.log(idSexo.target.value)
      sexo.value = idSexo.target.value;
    };

    const enviarInformacion = async () => {
      console.log("datos formulario");
      console.log(datosFormulario.value.email);
      console.log(datosFormulario.value.apellido);

      datosFormulario.value.departamento = departamento.value;
      datosFormulario.value.ciudad = ciudad.value;
      datosFormulario.value.poblado = poblado.value;
      datosFormulario.value.direccion = direccionCompleta.value;
      datosFormulario.value.direccionEntrega = direccionCompletaEntrega.value;
      datosFormulario.value.parentezcoRF = parentezco.value;
      datosFormulario.value.sexo = sexo.value;
      datosFormulario.value.estrato = estrato.value;

      if (datosFormulario.value.celularOpcional == "") {
        datosFormulario.value.celularOpcional = 0;
      }

      /*if (datosFormulario.value.referente == "") {
        datosFormulario.value.referente = 0
      };*/

      if (
        datosFormulario.value.cedula == undefined ||
        datosFormulario.value.cedula == "" ||
        datosFormulario.value.nombre == undefined ||
        datosFormulario.value.nombre == "" ||
        datosFormulario.value.apellido == undefined ||
        datosFormulario.value.apellido == "" ||
        datosFormulario.value.celular == undefined ||
        datosFormulario.value.celular == "" ||
        datosFormulario.value.email == undefined ||
        datosFormulario.value.email == "" ||
        datosFormulario.value.direccion == undefined ||
        datosFormulario.value.direccion == "" ||
        datosFormulario.value.fechaNacimiento == undefined ||
        datosFormulario.value.fechaNacimiento == "" ||
        datosFormulario.value.primerNombreRF == undefined ||
        datosFormulario.value.primerNombreRF == "" ||
        datosFormulario.value.primerApellidoRF == undefined ||
        datosFormulario.value.primerApellidoRF == "" ||
        datosFormulario.value.primerNombreRP == undefined ||
        datosFormulario.value.primerNombreRP == "" ||
        datosFormulario.value.primerApellidoRP == undefined ||
        datosFormulario.value.primerApellidoRP == "" ||
        datosFormulario.value.seccion == undefined ||
        datosFormulario.value.seccion == "" ||
        datosFormulario.value.telefonoRF == undefined ||
        datosFormulario.value.telefonoRF == "" ||
        datosFormulario.value.telefonoRP == undefined ||
        datosFormulario.value.telefonoRP == "" ||
        datosFormulario.value.barrio == undefined ||
        datosFormulario.value.barrio == "" ||
        datosFormulario.value.referente === "undefined" ||
        datosFormulario.value.referente === ""
      ) {
        //console.log('datos-formulario')
        //console.log(datosFormulario.value)

        Swal.fire({
          title: "Mensaje del sistema!",
          text: "Los campos con * deben ser diligenciados",
          icon: "error",
          confirmButtonText: "Cerrar",
          confirmButtonColor: "#F3566E",
        });

        return;
      }

      cerrarmodalLoading();

      await store.dispatch("envioDatosAnticipado", datosFormulario.value);

      //console.log("Mensaje final anticipado")
      //console.log(mensajeFinal.value)

      if (mensajeFinal.value == "OK") {
        cerrarmodalLoading();

        Swal.fire({
          title: "Mensaje del sistema!",
          text: "Registro Grabado con éxito",
          icon: "success",
          confirmButtonText: "Cerrar",
          confirmButtonColor: "#F3566E",
        });
        store.commit("LimpiarVariables");
        router.push("/");
      } else {
        cerrarmodalLoading();

        Swal.fire({
          title: "Mensaje del sistema!",
          text: mensajeFinal.value,
          icon: "error",
          confirmButtonText: "Cerrar",
          confirmButtonColor: "#F3566E",
        });
      }
      //console.log(datosFormulario.value);
    };

    const pasarPantalla2 = () => {
      store.commit("LimpiarVariables");
      router.push("/");
    };

    onMounted(() => getIdZona());

    return {
      mostrar,
      verificarCedula,
      on,
      off,
      parametros,
      estadoConsulta,
      backgroundColor,
      mostrarModal,
      tituloM,
      mensajeM,
      tipoM,
      limpiar,
      show,
      resultadoFinal,
      limpiarCedula,
      pasarPantalla2,
      modalDir,
      cerrarModalDir,
      lstDepartamentos,
      lstCiudades,
      lstPoblados,
      departamento,
      ciudad,
      poblado,
      setDepartamento,
      setCiudad,
      setPoblado,
      direccionCompleta,
      direccionCompletaEntrega,
      parentezco,
      setParentezco,
      datosFormulario,
      enviarInformacion,
      lstParentezcos,
      lstSexos,
      lstEstratos,
      setEstrato,
      setSexo,
      sexo,
      estrato,
      cedulaAsesora,
      celularAsesora,
      asesoraAux,
      mensajeFinal,
      cerrarmodalLoading,
      modalLoad,
      modalLoadEntrega,
      modalLoading,
      cerrarModalDirEntega,
      fechaNacimientoAsesora,
      modalPagoAnt,
      cerrarModalPagoAnticipado,
      mostrarModalAux,
      mensaReingreso,
      mensajeReingresoAux,
      disabled,
    };
  },
};
</script>





