<template>
  <div :class="fondoclass" class="h-4/5 ">
    <div :class="classModal" class="h-4/5">
      <div
        class="
          shadow-sm
          flex
          justify-center
          w-8
          h-8
          border-2 border-red-600
          rounded
          text-center
        "
      >
        <button @click="cerrarModal()">
          <p class="font-bold tect-red-500">x</p>
        </button>
      </div>
      <hr />

      <div class="overflow-auto h-full text-justify">
        <div class="grid justify-items-center ">
          <img :src="img" alt="" />
        </div>

        <div class="grid justify-items-center">

        <input
          type="hidden"
          name="remember"
          value="true"
        />

        <div class="rounded-md shadow-sm -space-y-px flex flex-col">
          <div class="ml-8 mb-4">
            <h1 class="text-xl mb-2 text-red-900">Genear Direccion Residencia</h1>
          </div>

          <div class=" md:flex mx-8">
            <div class="md:w-1/2 mr-2">
                 <select @change="setNomenclatura($event)" >
                    <option v-for="(lstNomenclatura,index) in lstNomenclaturas" :value="lstNomenclatura.nombre" :key="index">{{lstNomenclatura.nombre}}</option>
               </select>

            </div>

            <div class="md:w-1/2 mr-2">
                <input v-model="dir2" @keyup="direccionCompleta()"
                  id="DirNum1"
                  name="DirNum1"
                  type="text"
                  required
                  class="
                appearance-none
                rounded-none
                relative
                block
                w-full
                px-3
                py-1
                border border-gray-300
                placeholder-gray-500
                text-gray-900
                rounded-t-md
                focus:outline-none
                focus:ring-indigo-500
                focus:border-indigo-500
                focus:z-10
                sm:text-sm
                mb-2
              "
                  
                />
            </div>

            <label class="mr-2">#</label>

            <div class="md:w-1/2 mr-2">
             
                <input v-model="dir3" @keyup="direccionCompleta()"
                  id="DirNum2"
                  name="DirNum2"
                  type="text"
                  required
                  class="
                appearance-none
                rounded-none
                relative
                block
                w-full
                px-3
                py-1
                border border-gray-300
                placeholder-gray-500
                text-gray-900
                rounded-t-md
                focus:outline-none
                focus:ring-indigo-500
                focus:border-indigo-500
                focus:z-10
                sm:text-sm
                mb-2
              "
                  
                />
            </div>

            <div class="md:w-1/2 mr-2">
              
                <input v-model="dir4" @keyup="direccionCompleta()"
                  id="DirNum3"
                  name="DirNum3"
                  type="DirNum3"
                  required
                  class="
                appearance-none
                rounded-none
                relative
                block
                w-full
                px-3
                py-1
                border border-gray-300
                placeholder-gray-500
                text-gray-900
                rounded-t-md
                focus:outline-none
                focus:ring-indigo-500
                focus:border-indigo-500
                focus:z-10
                sm:text-sm
                mb-2
              "
                />
            </div>

          </div>

           <div class=" md:flex mx-8">
            <div class="md:w-full mr-2">
              <label
                for="IdComplemento"
                class=""
              >Complemento </label>
                <input v-model="dir5" @keyup="direccionCompleta()"
                  id="IdComplemento"
                  name="IdComplemento"
                  type="text"
                  required
                  class="
                appearance-none
                rounded-none
                relative
                block
                w-full
                px-3
                py-1
                border border-gray-300
                placeholder-gray-500
                text-gray-900
                rounded-t-md
                focus:outline-none
                focus:ring-indigo-500
                focus:border-indigo-500
                focus:z-10
                sm:text-sm
                my-4
              "
                  placeholder="Complemento"
                />
            </div>
          </div>

          <div class=" md:flex mx-8 ">
            <div class="md:w-full mr-2">
              <label
                for="IdverificarDir"
                class=""
              >Verificar Dirección </label>
                <input v-model="dirFinal"
                  disabled
                  id="IdverificarDir"
                  name="IdverificarDir"
                  type="text"
                  required
                  class="
                appearance-none
                rounded-none
                relative
                block
                w-full
                px-3
                py-1
                border border-gray-300
                placeholder-gray-500
                text-gray-900
                rounded-t-md
                focus:outline-none
                focus:ring-indigo-500
                focus:border-indigo-500
                focus:z-10
                sm:text-sm
                mt-4
                text-center
              "
                  placeholder="Verificar Dirección"
                />
            </div>
          </div>



        </div>

        <div>
          <button
            
            class="
              group
              relative
              w-full
              flex
              justify-center
              py-2
              px-4
              border border-transparent
              text-sm
              font-medium
              rounded-md
              text-white
              bg-pink-800
              hover:bg-pink-700
              focus:outline-none
              focus:ring-2 focus:ring-offset-2 focus:ring-pink-500
              mt-4
            "
            @click="setDireccionCompleta()"
           
          >
           
            Aceptar
            </button>
        </div>
     
       
        </div>
        <!--<div class="grid justify-items-center">
          <img :src="img" alt="" />
        </div>-->
      </div>
    </div>
  </div>
</template>
<script>

import img from "../assets/logos/LogoJuana.png";
import { ref } from "@vue/reactivity";
import { computed,onMounted,watchEffect } from "vue";
import { useStore } from "vuex";
import { async } from 'q';
export default {
  name: "noticeModal",

  setup() {
    const store = useStore();
    const classModal = ref(
      "border border-4 border-gray-400 shadow rounded shadow bg-white grid grid-cols-1 justify-items-center   relative mx-2 z-50  px-3 py-2 flex items-center justify-center rounded absolute right-0 transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 mt-8 lg:mt-10"
    );
    const fondoclass = ref(
      "z-10 z-40 overflow-auto scrolling-touch p-3 left-0 top-0 bottom-0 right-0  fixed bg-opacity-50 bg-gray-900 "
    );
    const cerrarModal = () => {
      store.commit("ModalDireccion");
    };

    const dir1 = ref('');
    const dir2 = ref('');
    const dir3 = ref('');
    const dir4 = ref('');
    const dir5 = ref('');
    const dir6 = ref('');
    const dirFinal = ref('');
    const codNomenclatura = ref('');
    const lstPreguntas = computed(() => store.state.preguntas);

    const lstNomenclaturas = computed(() => {
      return store.state.nomenclaturas;
    });

    const direccionCompleta = () => {

      dirFinal.value = ''

      dir6.value =  dir2.value +  ' ' + '#' +  ' ' + dir3.value + ' ' + '-' + ' ' + dir4.value +  ' ' + dir5.value  
      let primerCaracter = dir6.value.charAt(0);
      if (isNaN(primerCaracter) === true) {
        dir2.value = ''
        dir6.value = ''
      }
      //console.log(isNaN(primerCaracter))

      dirFinal.value = codNomenclatura.value + ' ' +  dir6.value

    };

    const setDireccionCompleta = () => {
      let dirAux = codNomenclatura.value + ' ' + dir6.value
        store.dispatch("getDireccionCompleta",dirAux);
        cerrarModal();
    };

    const getNomenclatura = async () => {
      await  store.dispatch("getNomenclatura");
        cerrarModal();
          codNomenclatura.value = lstNomenclaturas.value[0].nombre
        
    };

     const setNomenclatura = (idNomenclatura) => {
      codNomenclatura.value = idNomenclatura.target.value;
      direccionCompleta()
    };

     onMounted(() => getNomenclatura());

    return {
      classModal,
      fondoclass,
      cerrarModal,
      img,
      dir1,dir2,dir3,dir4,dir5,dir6,
      direccionCompleta,
      setDireccionCompleta,
      getNomenclatura,
      lstNomenclaturas,
      codNomenclatura,
      setNomenclatura,
      dirFinal

    };
  },
};
</script>

<style>
</style>