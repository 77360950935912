<template >
  <div class="cargaInicial">
    
  </div>
</template>


<script>
// @ is an alias to /src
import { computed,onMounted } from "vue";
import { useStore } from "vuex";
import { ref } from "@vue/reactivity";
import axios from "axios";
import router from "../router/index.js";

export default {
  name: "cargaInicial",

  setup() {

     onMounted(() => 
        router.push("/")
    )
    return {
      
    };
  },
};


</script>
<style scoped>

</style>
